import React, { useRef, useState } from 'react'
import {
  BottomSheet,
  Box,
  Button,
  Header,
  InputGroup,
  MoreBar,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'

import { GenericEditableTable } from '@src/features/GenericEditableTable/GenericEditableTable'
import { CreateTeamPopup } from '@src/features/GenericEditableTable/CellPopups/CreateTeam'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { CreateEntityPopup } from '@src/features/GenericEditableTable/CellPopups/CreateEntity'
import { CreateLocationPopup } from '@src/features/GenericEditableTable/CellPopups/CreateLocation'
import { CreateDepartmentPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDepartment'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { EmployeesSimpleCreateInterface } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { IdAndName } from '@src/interfaces'
import { createEmployeeSimple } from '@src/api/employees'
import { AccessGroupSelectorOption } from '@components/AccessGroup/AccessGroupSelectorOption'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CreateNewItemType, EditableEmployeesTableProps } from '.'
import { useOnboardingContext } from '@src/pages/OnboardingChecklistV2/components/OnboardingContext'

export const TableView = ({
  row,
  hiddenColumns,
  sessionRoute,
  isPreview,
  previewActions,
  renderEditActionsLeft,
  renderEditActionsRight,
  useMainAddForm,
  bulkSessionFieldsMap,
}: EditableEmployeesTableProps) => {
  const { setNextButtonState } = useOnboardingContext()
  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()
  const [addEmployeePopupOpen, setAddEmployeePopupOpen] = useState(false)

  const tableRefreshRef = useRef<() => void>()

  const onCreateNew = (
    type: CreateNewItemType,
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  return (
    <>
      <GenericEditableTable
        isPreview={isPreview}
        apiEndpoint={API.EMPLOYEES_SIMPLE}
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew, isPreview ? 'preview' : 'default')}
        entity="employee"
        variant="existingEntities"
        hiddenColumns={hiddenColumns}
        sortByInitial={[{ sortBy: 'email', nonResettable: true }]}
        onChangeValidationState={state => {
          if (state === 'valid') {
            setNextButtonState('active')
          } else if (state === 'invalid') {
            setNextButtonState('disabled')
          }
        }}
        tableActions={tableActionsProps => (
          <MoreBar>
            <MoreBar.Action
              useIcon="Plus"
              onClick={
                useMainAddForm
                  ? () => navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.NEW))
                  : () => {
                      setAddEmployeePopupOpen(true)
                      tableRefreshRef.current = tableActionsProps.refreshTableState
                    }
              }
            >
              Add employee
            </MoreBar.Action>

            {isPreview ? (
              previewActions
            ) : (
              <>
                {renderEditActionsLeft?.(tableActionsProps)}
                <BulkEditExistingEntitiesAction
                  sessionRoute={sessionRoute}
                  buttonIcon="Suitcase"
                  field="team"
                  selector={selectorKeys.team}
                  {...tableActionsProps}
                  apiEndpoint={API.EMPLOYEE_UPLOADS}
                  fieldsForBulkEdit={bulkSessionFieldsMap.team}
                />
                <BulkEditExistingEntitiesAction
                  sessionRoute={sessionRoute}
                  buttonIcon="RepairTool"
                  field="specialisation"
                  selector={selectorKeys.specialisations}
                  {...tableActionsProps}
                  apiEndpoint={API.EMPLOYEE_UPLOADS}
                  label="role"
                  fieldsForBulkEdit={bulkSessionFieldsMap.specialisation}
                />
                <BulkEditExistingEntitiesAction
                  sessionRoute={sessionRoute}
                  buttonIcon="ArrowRightLeft"
                  field="seniority"
                  selector={selectorKeys.seniority}
                  {...tableActionsProps}
                  apiEndpoint={API.EMPLOYEE_UPLOADS}
                  fieldsForBulkEdit={bulkSessionFieldsMap.seniority}
                />
                {renderEditActionsRight?.(tableActionsProps)}
              </>
            )}
          </MoreBar>
        )}
      />

      <CreateEmployeePopup
        open={addEmployeePopupOpen}
        onSuccess={() => {
          tableRefreshRef.current?.()
          setAddEmployeePopupOpen(false)
        }}
        onClose={() => {
          setAddEmployeePopupOpen(false)
        }}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateEntityPopup
        open={createNewTypeState?.type === 'companyEntity'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateLocationPopup
        open={createNewTypeState?.type === 'location'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
        showSuccessPopup={false}
      />

      <CreateDepartmentPopup
        open={createNewTypeState?.type === 'department'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}

interface CreateEmployeePopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
}

const CreateEmployeePopup = ({ open, onSuccess, onClose }: CreateEmployeePopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add employee</Header.Title>
      </Header>

      <LapeForm<EmployeesSimpleCreateInterface>
        onSubmit={form => {
          setPending(true)

          return createEmployeeSimple(form.values)
            .then(() => {
              onSuccess()
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateEmployeeForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateEmployeeForm = () => {
  const statusPopup = useStatusPopup()

  const { isSubmitting, submit } = useLapeContext<EmployeesSimpleCreateInterface>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="email" label="Email" required />
        <LapeNewInput name="first_name" label="First name" required />
        <LapeNewInput name="last_name" label="Last name" required />
        <LapeRadioSelectInput name="team" label="Team" selector={selectorKeys.team} />
        <InputGroup variant="horizontal">
          <Box width="60%">
            <LapeRadioSelectInput<{ id: number; name: string; status?: 'archived' }>
              name="specialisation"
              label="Role"
              selector={selectorKeys.specialisations}
              filter={i => i.status !== 'archived'}
            />
          </Box>
          <Box width="40%">
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
            />
          </Box>
        </InputGroup>
        <LapeRadioSelectInput
          name="access_groups"
          label="Access group"
          selector={selectorKeys.groups}
        >
          {option => <AccessGroupSelectorOption {...option.value} />}
        </LapeRadioSelectInput>
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create employee</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
